<template>
  <div id="data-list-list-view" class="data-list-container">
    <div v-if="loading" class="loading-pagination">
      <SpinnerColor />
    </div>
    <vs-table ref="table" v-model="selected" noDataText="" :max-items="costData.itemsPerPage" :data="data">
      <div slot="header" class="flex flex-wrap items-center flex-grow">
        <div
          class="flex flex-wrap-reverse justify-between items-center data-list-btn-container w-full"
          style="position: relative"
        >
          <!-- TITLE TABLE -->
          <div>
            <feather-icon icon="HomeIcon" svgClasses="h-4 w-4"></feather-icon>
            <h3 class="font-semibold ml-3" style="display: inline-block; font-size: 1.125rem">
              {{ $t('client_office_costs') }}
            </h3>
          </div>

          <!-- Search -->
          <div style="position: relative; width: 38%">
            <input
              v-model.trim="searchValue"
              class="search-mission search-cost w-full"
              :placeholder="$t('searchByGovernZone')"
              @keyup.enter="handleSearch"
            />

            <i class="icon-advanced-search" @click="handleSearch">
              <p v-if="errSearch" class="err-search">{{ 'Search error' }}</p>
              <vx-tooltip text="إضغط للبحث" delay="0.2s" position="bottom">
                <svg xmlns="http://www.w3.org/2000/svg" width="16.414" height="16.653" viewBox="0 0 16.414 16.653">
                  <g id="search" transform="translate(1 1)">
                    <circle
                      id="Ellipse_274"
                      data-name="Ellipse 274"
                      cx="6"
                      cy="6"
                      r="6"
                      fill="none"
                      stroke="#6e6b7b"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                    <line
                      id="Line_69"
                      data-name="Line 69"
                      x1="3.469"
                      y1="3.469"
                      transform="translate(10.531 10.77)"
                      fill="none"
                      stroke="#6e6b7b"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                  </g>
                </svg>
              </vx-tooltip>
            </i>
            <span style="position: absolute; right: 0; top: 75%; color: red; font-size: 12px" v-if="errSearchMsg">{{
              errSearchMsg
            }}</span>
          </div>
        </div>
      </div>

      <template slot="thead">
        <vs-th :key="index" v-for="(head, index) in header" :sort-key="head.key">{{ $t(head.head) }}</vs-th>
      </template>
      <!-- COSTS -->
      <tbody>
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in allCosts">
          <vs-td :key="index" v-for="(td, index) in body">
            <p class="product-due_date font-medium truncate">{{ tr[td] }}</p>
            <vs-chip class="product-sender" style="font-size: 0.5625rem" v-if="tr[td] == null">لا يوجد</vs-chip>
          </vs-td>
        </vs-tr>
      </tbody>
    </vs-table>
    <!-- Loading Search -_- -->
    <div class="p-6 loading-table" v-if="loadAdvanceCost">
      <SpinnerColor />
    </div>

    <div v-if="hasData" class="flex justify-between items-center px-6 responsive-footer">
      <!-- ITEMS PER PAGE -->
      <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4 items-per-page-handler">
        <div
          style="
            font-family: 'Cairo', sans-serif !important;
            padding: 0.75rem !important;
            border-radius: 0.25rem !important;
          "
          class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
        >
          <span class="mr-2"
            >{{ currentPage }} - {{ costData.itemsPerPage * costData.pageNum }} {{ $t('of') }}
            {{ feed_count_costs }}</span
          >
          <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
        </div>
        <vs-dropdown-menu>
          <ul style="min-width: 8rem">
            <vs-dropdown-item @click="costData.itemsPerPage = 10" class="m-0 p-0">
              <li class="flex py-4 px-4 cursor-pointer profile-drop">
                <span>10</span>
              </li>
            </vs-dropdown-item>
            <vs-divider class="m-0" />
            <vs-dropdown-item @click="costData.itemsPerPage = 20" class="m-0 p-0">
              <li class="flex py-4 px-4 cursor-pointer profile-drop">
                <span>20</span>
              </li>
            </vs-dropdown-item>
            <vs-divider class="m-0" />
            <vs-dropdown-item @click="costData.itemsPerPage = 50" class="m-0 p-0">
              <li class="flex py-4 px-4 cursor-pointer profile-drop">
                <span>50</span>
              </li>
            </vs-dropdown-item>
            <vs-divider class="m-0" />
            <vs-dropdown-item @click="costData.itemsPerPage = 100" class="m-0 p-0">
              <li class="flex py-4 px-4 cursor-pointer profile-drop">
                <span>100</span>
              </li>
            </vs-dropdown-item>
          </ul>
        </vs-dropdown-menu>
      </vs-dropdown>
      <div>
        <vs-pagination
          :total="totalPage"
          v-model="costData.pageNum"
          :prev-icon="$vs.rtl ? 'arrow_forward' : 'arrow_back'"
          :next-icon="$vs.rtl ? 'arrow_back' : 'arrow_forward'"
        />
      </div>
    </div>
    <empty-data v-else-if="!hasData" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import EmptyData from './EmptyData.vue';
import SpinnerColor from './SpinnerColor2.vue';
import globalMixin from '@/mixins/mixins.js';
import emptyTable from '@/mixins/datatableEmpty.js';
import helper from '@/mixins/helper.js';
import notify from '@/mixins/notifyState.js';
import SelectInputVue from '@/layouts/components/SelectInput.vue';

export default {
  name: 'data-table-office-cost',
  mixins: [globalMixin, emptyTable, helper, notify],
  components: {
    SpinnerColor,
    EmptyData,
    SelectInputVue,
  },
  props: {
    data: {
      type: Array,
    },
    header: {
      type: Array,
      required: true,
    },
    body: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selected: [],
      // checkSelect: false,
      isMounted: false,
      popupActive: false,
      id: '',
      costData: {
        pageNum: 1,
        itemsPerPage: 10,
      },
      searchValue: '',
      errSearch: false,
      errSearchMsg: '',
      reasonOfCantDelete: '',
      loadAdvanceCost: false,
      loading: false,
      loadReasons: false,
    };
  },
  computed: {
    ...mapGetters('dataList', ['costs', 'feed_count_costs']),
    ...mapState('dataList', ['next_page_url_cancelation_reasons']),
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx;
      }
      return 0;
    },
    queriedItems() {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.data.length;
    },
    totalPage() {
      return Math.ceil(this.feed_count_costs / this.costData.itemsPerPage);
    },
    allCosts() {
      return this.data;
    },
  },
  watch: {
    'costData.pageNum': {
      handler() {
        this.updateCosts();
      },
      deep: true,
    },
    'costData.itemsPerPage': {
      handler() {
        this.updateCosts();
      },
      deep: true,
    },
    costs: {
      handler: function () {
        this.tableHasData(this.costs);
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions('dataList', ['fetchDataOfficeCosts', 'searchOfficeCosts']),

    async handleSearch() {
      try {
        this.loadAdvanceCost = true;
        this.loading = true;
        if (this.searchValue) {
          await this.searchOfficeCosts({
            query: this.searchValue,
            ...this.costData,
          });
        } else {
          await this.updateCosts();
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.loadAdvanceCost = false;
        this.loading = false;
      }
    },

    async updateCosts() {
      this.loading = true;
      await this.fetchDataOfficeCosts(this.costData);
      this.loading = false;
    },
    async infiniteHandler($state) {
      if (!this.next_page_url_cancelation_reasons) {
        $state.complete();
      } else {
        $state.loaded();
      }
    },
  },

  mounted() {
    this.isMounted = true;
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/vuexy/_dataTable.scss';
.con-vs-popup .vs-popup {
  max-height: 450px;
  .custom-select {
    display: block;
    height: auto;
    .selected {
      min-height: 37px;
    }
    .items {
      position: unset;
    }
  }
}
.vs-popup--content {
  max-height: 450px;
  height: 100%;
}
</style>
<style lang="scss" scoped>
@import '@/assets/scss/vuexy/_loadingTable.scss';
.search-cost {
  width: 38%;
  height: 38px;
  border: 1px solid #d8d6de;
  border-radius: 4px;
  padding: 0 0.75rem;
  color: #212121;
  margin-top: 15px;
  margin-bottom: 15px;
}
.icon-advanced-search {
  position: absolute;
  right: 10px;
  top: 25px;
  cursor: pointer;
}
.err-search {
  position: absolute;
  right: 310px;
  bottom: -21px;
  z-index: 999;
  min-width: 200px;
  color: red;
  font-size: 12px;
}
</style>
